import React, { useState } from "react";
import Block from "./block";

import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";
import Project from "./project";
import Img from "gatsby-image";

function Works({ content, title, projects, logos }) {
  let contentHTML = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(content)
    .toString();

  let titleHTMl = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(title)
    .toString();

  return (
    <Block
      className={`bloom-bg-white bloom-border-t bloom-border-b bloom-border-green`}
    >
      <div className={`bloom-mb-5 bloom-flex`}>
        <h2
          className={`bloom-text-4xl bloom-uppercase bloom-font-bold bloom-border-b-4 bloom-border-green`}
        >
          Our partners
        </h2>
      </div>
      <div className={`bloom-flex bloom-flex-wrap bloom--mx-10`}>
        {logos.map((logo, index) => {
          return (
            <div
              className={`bloom-w-1/2 sm:bloom-w-1/3 bloom-px-10 bloom-mt-10`}
              key={`work-` + index}
            >
              <Img alt={logo.title} fluid={logo.image.childImageSharp.fluid} />
            </div>
          );
        })}
      </div>
    </Block>
  );
}

export default Works;
