import React, { useState } from "react";

import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";
import Img from "gatsby-image";

function Service({ count, content, title, icon, image }) {
  let contentHTML = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(content)
    .toString();

  return (
    <div className={`bloom-mt-12`}>
      <h3
        className={`bloom-flex bloom-items-center bloom-text-3xl bloom-mb-6 bloom-font-bold bloom-uppercase`}
      >
        <div className={`bloom-mr-3 bloom-w-7`}>
          <img alt={title} role={`presentation`} src={icon.publicURL} />
        </div>
        {title}
      </h3>
      <div className={`bloom-flex bloom-flex-wrap bloom--mx-3`}>
        <div className="bloom-w-full md:bloom-w-1/4 sm:bloom-w-1/3 bloom-px-3 bloom-mb-6 sm:bloom-mb-0">
          <Img fluid={image.childImageSharp.fluid} />
        </div>
        <div className="bloom-w-full md:bloom-w-3/4 sm:bloom-w-2/3 bloom-px-3">
          <div
            className={`bloom-text-xl bloom-help`}
            dangerouslySetInnerHTML={{ __html: contentHTML }}
          />
        </div>
      </div>
    </div>
  );
}

export default Service;
