import React, { useState } from "react";
import Block from "./block";

import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";
import Service from "./service";
import BloomGreen from "../../static/img/bloom-green-bg.svg";

function Services({ content, title, services }) {
  let contentHTML = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(content)
    .toString();

  let titleHTMl = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(title)
    .toString();

  return (
    <Block bgImage={BloomGreen} className={`bloom-bg-white`}>
      <div className={`bloom-mb-5 bloom-flex`}>
        <h2
          className={`bloom-text-4xl bloom-uppercase bloom-font-bold bloom-border-b-4 bloom-border-green`}
          dangerouslySetInnerHTML={{ __html: titleHTMl }}
        />
      </div>
      <div
        className={`bloom-text-xl sm:bloom-pr-6`}
        dangerouslySetInnerHTML={{ __html: contentHTML }}
      />
      {services.map((service, index) => {
        return (
          <Service
            content={service.content}
            count={index}
            icon={service.icon}
            image={service.image}
            key={index}
            title={service.title}
          />
        );
      })}
    </Block>
  );
}

export default Services;
