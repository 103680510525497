import React, { useState } from "react";
import Block from "./block";

import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";
import quoteImg from "../../static/img/bloom-white.svg";

function Quote({ content, title }) {
  let contentHTML = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(content)
    .toString();

  let titleHTMl = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(title)
    .toString();

  return (
    <Block className={`bloom-bg-seafoam`}>
      <div
        className={`bloom-flex bloom-flex-wrap bloom--mx-6 bloom-items-center`}
      >
        <div
          className={`sm:bloom-w-1/4 bloom-hidden sm:bloom-block bloom-px-6`}
        >
          <img alt={`Bloom`} src={quoteImg} />
        </div>
        <div className={`bloom-w-full sm:bloom-w-3/4 bloom-px-6`}>
          <div
            className={`bloom-text-3xl bloom-mb-5 bloom-leading-tight`}
            dangerouslySetInnerHTML={{ __html: titleHTMl }}
          />
          <div
            className={`bloom-text-xl`}
            dangerouslySetInnerHTML={{ __html: contentHTML }}
          />
        </div>
      </div>
    </Block>
  );
}

export default Quote;
