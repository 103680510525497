import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroText from "../components/heroText";
import Hero from "../components/hero";
import Quote from "../components/quote";
import Services from "../components/services";
import Works from "../components/works";
import Subfooter from "../components/subfooter";
import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";
import Block from "../components/block";

function IndexPage({ location, data }) {
  const content = data.indexPage.frontmatter;
  const teamPhotos = data.teamPage.frontmatter;

  return (
    <Layout location={location}>
      <SEO keywords={[`bloom`, `bloom works`]} title="Bloom Works" />
      <HeroText
        bg={content.hero.image}
        className={`bloom-pt-hero lg:bloom-pt-heroXL`}
      />
      <Hero
        bg={content.hero.image}
        className={`bloom-pt-hero lg:bloom-pt-heroXL`}
      />
      <Quote content={content.intro.content} title={content.intro.title} />
      {content.blocks &&
        content.blocks.length > 0 &&
        content.blocks.map((block, index) => {
          let contentHTML = remark()
            .use(recommended)
            .use(remarkHtml)
            .processSync(block.content)
            .toString();

          return (
            <Block className={`bloom-bg-white`} key={`index-` + index}>
              <div className={`bloom-mb-5 bloom-flex`}>
                <h2
                  className={`bloom-text-4xl bloom-uppercase bloom-font-bold bloom-border-b-4 bloom-border-green`}
                >
                  {block.title}
                </h2>
              </div>
              <div
                className={`bloom-prose`}
                dangerouslySetInnerHTML={{ __html: contentHTML }}
              />
            </Block>
          );
        })}
      <Services
        content={content.services.content}
        services={content.services.service}
        title={content.services.title}
      />
      <Works
        content={content.work.content}
        logos={content.logos}
        projects={content.work.project}
        title={content.work.title}
      />
      <Subfooter people={teamPhotos.people.person}></Subfooter>
    </Layout>
  );
}

export const pageQuery = graphql`
  query IndexTemplate {
    indexPage: markdownRemark(frontmatter: { templateKey: { eq: "index" } }) {
      html
      frontmatter {
        intro {
          title
          content
        }
        blocks {
          title
          content
        }
        hero {
          image {
            childImageSharp {
              fluid(maxWidth: 2000, maxHeight: 750, cropFocus: SOUTH) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        work {
          title
          content
          project {
            title
            content
            image {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 250) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        logos {
          title
          image {
            childImageSharp {
              fluid(maxHeight: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        services {
          title
          content
          service {
            title
            content
            icon {
              extension
              publicURL
            }
            image {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 250) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    teamPage: markdownRemark(frontmatter: { templateKey: { eq: "team" } }) {
      html
      frontmatter {
        people {
          person {
            name
            title
            image {
              childImageSharp {
                fluid(
                  maxWidth: 800
                  maxHeight: 800
                  duotone: {
                    highlight: "#ffffff"
                    shadow: "#cccccc"
                    opacity: 100
                  }
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
